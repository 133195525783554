/* Import Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* Import Open Sans font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* Import Lato font */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* Import Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* Import Cedarville Cursive font */
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

.workContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.canvasWrap {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.canvasBody {
  position: relative;
  width: 80%;
  max-width: 900px;
}

.canvasStage canvas {
  background-color: white !important;
  box-shadow: 0 2px 8px rgb(14 19 24 / 7%);
}

.toolbar {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.toolbar button {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}

.toolbar button:hover {
  background-color: #f0f0f0;
}

.workContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.bottomButtons {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bottomButtons button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.font-size-control {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust margin as needed */
}

.font-size-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.font-size {
  margin: 0 8px;
}

.font-family-control {
  min-width: 150px; /* Adjust width as needed */
}


/* responsive */
@media screen and (max-width: 1024px) {
  .workContainer {
    flex-direction: column-reverse;
  }
}