/* general css */
.itemsListWrap {
  height: 100%;
  z-index: 1;
  display: flex;
  transition: all .4s ease-in-out;
  overflow: hidden;
  flex-direction: row-reverse;
}
.itemsListBody {
  background: var(--dark);
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  
}
/* end of general css */

/* toolsBar css */
.toolsBarWrap {
  display: flex;
  flex-direction: row;
  height: 72px;
  position: relative;
}
.toolsBarBody {
  min-width: 72px;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  position: relative;
  height: 100%;
}
.toolsItemsWrap {
  display: flex;
  flex-direction: row;
}
.toolsItem {
  height: 72px;
  width: 72px;
  position: relative;
}
.toolsItemContent {
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: var(--gray);
  cursor: pointer;
  margin-top: 20px;
}
.toolIcon {
  width: 24px;
  height: 24px;
}
.toolTitle {
  display: block;
  padding: 0 2px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  height: 18px;
  line-height: 18px;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 11px;
}
/* end of toolBar css */

/* itemsSection css */
/* select filter */
.categorySelectLabel {
  position: relative;
  display: block;
  margin: 50px auto 50px;
  width: 90%;
}
.categorySelectLabel::after {
  content: '▼';
  position: absolute;
  width: 27px;
  color: #999;
  font-weight: bold;
  font-size: 16px;
  right: 0px;
  bottom: 8px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 2;
}
.categorySelectLabel::before {
  content: '';
  right: 2px;
  top: 2px;
  width: 38px;
  height: 34px;
  background: #242424;
  position: absolute;
  pointer-events: none;
  display: block;
  z-index: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.categorySelect {
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #111;
  color: #999;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 10px 9px;
  margin: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  height: 38px;
}
.categorySelect option {
  font-size: 17px;
}
/* end of select  */
/* backgrounds text  */
.clearBackgroundWrap {
  position: relative;
  display: block;
  margin: 50px auto 50px;
  width: 90%;
  text-align: center;
}
.clearBackgroundText {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: color .15s ease-in-out;
}
.clearBackgroundText:hover {
  color:#999
}
.clearBackgroundText::before {
  content: '\261B';
}
/* end of backgrounds text */
/* upload image */
.uploadImageWrap {
  position: relative;
  display: block;
  margin: 50px auto 50px;
  width: 90%;
  text-align: center;
}
.uploadImageButton {
  padding: 10px 50px;
  border-radius: 15px;
  color: white;
  background-color: #EF5F63;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color .15s ease-in-out;
  cursor: pointer;
}
.uploadImageButton:hover {
  background-color: #c04c50;
}
.uploadTooltip {
  color: #fff;
  text-align: center;
}
/* end of upload image */
.itemsSection {
  height: 100%;
  background-color: var(--matDark);
  padding: 10px 5px;
  display: inline-block;
}
.itemsWrapper {
  overflow: auto;
  max-height: calc(95% - 150px);
}
.itemsImage {
  height: 100px;
  max-width: 200px;
  object-fit: contain;
  border: 1px solid var(--gray);
  border-radius: 10px;
  margin: 5px 0;
}
.imageContainer {
  display: inline-block;
  width: max-content;
  margin: 0 5px;
}
/* share section */
.shareSectionWrap {
    position: relative;
    display: block;
    margin: 50px auto 50px;
    width: 90%;
    text-align: center;
}
.downloadImage {
  background-color: #EF5F63;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color .15s ease-in-out;
  cursor: pointer;
}
.downloadImage:hover {
  background-color: #c04c50;
}
.uploadImage {
  background-color: #EF5F63;
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color .15s ease-in-out;
  cursor: pointer;
}
.uploadImage:hover {
  background-color: #c04c50;
}
/* end of sharing section */
/* expand button */
.expandButton {
  width: auto;
  height: 100px;
  margin: auto 0;
  background-color: #252627;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 0 50px 50px 0;
  cursor: pointer;
}
.sidebarOpen .expandButton svg {
  transform: rotate(270deg);
}
.sidebarClosed .expandButton svg {
  transform: rotate(90deg);
}
.expandButton svg {
  transition: all .5s ease-in-out;
}
/* end of expand button */

/* end of itemsSection css */

/* responsive */
.sidebarOpen {
  width: 40%;
  max-width: 432px;
}
.sidebarClosed {
  width: 24px;
  max-width: 432px;
}
@media screen and (max-width: 1024px) {
    .itemsListWrap {
      height: 90%;
      position: absolute;
      flex-direction: column;
    }
    .sidebarOpen {
      width: 100%;
      max-width: none;
    }
    .sidebarClosed {
      height: 15%;
      width: 100%;
      max-width: none;
    }
    .sidebarOpen .expandButton svg {
      transform: rotate(0);
    }
    .sidebarClosed .expandButton svg {
      transform: rotate(180deg);
    }
    .expandButton {
      border-radius: 50px 50px 0 0;
      width: 100px;
      height: auto;
      margin: 0 auto;
    }
}
/* end of responsive */